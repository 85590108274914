import React from 'react';

import ServicesLayout from '../../components/ServicesLayout';
import HRAImage from '../../images/image_HRA.jpg';

const Enrollment = () => (
  <ServicesLayout title="Simplifying HRA Options" bannerTitle="Simplifying HRA Options" bannerCopy="Donec facilisis tincidunt orci non vestibulum. Phasellus pulvinar ante eu vulputate dapibus. Quisque sodales metus at urna dictum consectetur.">
    <h2 className="inner-page__heading">Health Reimbursement Arrangements (HRA)</h2>
    <p className="inner-page__lead">Customized plan administration and employee education for any stand-alone or integrated HRA plan.</p>
    <img className="inner-page__hero" src={HRAImage} alt="Husband and wife at laptop looking at their health reimbursement arrangements" />

    <p>With the compounding cost increases of many medical plans, employer-funded Health Reimbursement Arrangements (HRAs) are an increasingly popular alternative.  Tri-Star helps employers design HRAs with plan deductibles and tiered or percentage cost sharing to give participants and companies another way of managing their healthcare costs.</p>

    <p>Tri-Star Service Highlights:</p>
    <ul>
      <li><strong>Customized Plan Designs,</strong> including stand-alone HRAs or integrated HRAs coordinated with HSAs and FSAs</li>
      <li><strong>Enrollment Education</strong> with clear communication materials to help participants fully understand their HRA and how to make the most of it</li>
      <li><strong>Ongoing Administration</strong> that makes it easy for employers to monitor and manage their plans, and simple for participants to access their healthcare funds</li>
    </ul>

    <p>HRAs can often be designed to provide key benefits over traditional medical plans:</p>
    <ul>
      <li>Reduce employer premium costs with higher deductible plans while minimizing the impact on employees</li>
      <li>Allow carryover and accumulation of annual unused account balances</li>
      <li>Provide tax-sheltered payouts to retirees for unused sick and vacation pay</li>
      <li>Address post-employment benefit needs for employees and retirees</li>
    </ul>
  </ServicesLayout>
);

export default Enrollment
